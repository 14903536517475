
    .page-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #333;
        margin: 20px 0;
        line-height: 1;
    }
    .goodsPurchase-container {
        background: #fff;
        padding: 20px;
        min-height: calc(100vh - 194px);
        /*height: calc(100vh - 189px);*/
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    .search-keywords {
        display: flex;
        align-items: center;
        .el-input {
            flex: 1;
            width: 1%;
            margin-left: 10px;
        }
    }
